const ResultsDisplay = ({ quantities, totalPrice, daoShare, rewardsPerYeti, hourlyRate, totalQuests, totalReductions, rewardsPerYetiInEGLD }) => {
    return (
      <div id="results">
        <h2>Results</h2>
        <div className="resultColumn">
            <div>
                <p>Total quest : {totalQuests}</p>
                <p>Total price : ${totalPrice.toFixed(2)}</p>
                <p>Total discount : ${totalReductions.toFixed(2)}</p>
            </div>
            <div>
                <p>DAO share : ${daoShare.toFixed(2)}</p>
                <p>Rewards per yeti : ${rewardsPerYeti.toFixed(2)} / {rewardsPerYetiInEGLD.toFixed(3)}$EGLD</p>
                <p>Hourly Rate : ${hourlyRate.toFixed(2)}</p>
        </div>
      </div>
      </div>
    );
  };
  
  
  export default ResultsDisplay;
  
