import React from 'react';

const Reduction = ({ budgetPrevisionnel, onBudgetPrevisionnelChange, doxxed, onDoxxedChange, paiementHorsMint, onPaiementHorsMintChange }) => {
  const handleBudgetPrevisionnelChange = (e) => {
    const value = e.target.checked;
    onBudgetPrevisionnelChange(value);
  };

  const handleDoxxedChange = (e) => {
    const value = e.target.checked;
    onDoxxedChange(value);
  };

  const handlePaiementHorsMintChange = (e) => {
    const value = e.target.checked;
    onPaiementHorsMintChange(value);
  };

  return (
    <div>
      <h2>Discounts</h2>
      <div className='choiceReduction'>
      <label className='label-container'>
        Budget Forecast ?
        <input
          type="checkbox"
          checked={budgetPrevisionnel}
          onChange={handleBudgetPrevisionnelChange}
        />
      </label>
      <label className='label-container'>
        Doxxed ? 
        <input
          type="checkbox"
          checked={doxxed}
          onChange={handleDoxxedChange}
        />
      </label>
      <label className='label-container'>
        Payment off mint ? 
        <input
          type="checkbox"
          checked={paiementHorsMint}
          onChange={handlePaiementHorsMintChange}
        />
      </label>
      </div>
    </div>
  );
};

export default Reduction;
