import React, { useState, useEffect } from 'react';
import './App.css';
import QuantitiesInput from './QuantitiesInput';
import ResultsDisplay from './ResultsDisplay';
import Reduction from './Reduction';
import pricesData from './prices.json'; 

const App = () => {
  const [quantities, setQuantities] = useState({
    thread: 0,
    tweet: 0,
    follow: 0,
    discord: 0,
    likeRT: 0,
    raid: 0,
  });
  const [numberOfYetis, setNumberOfYetis] = useState(0);
  const [budgetPrevisionnel, setBudgetPrevisionnel] = useState(false);
  const [doxxed, setDoxxed] = useState(false);
  const [paiementHorsMint, setPaiementHorsMint] = useState(false);
  const [conversionRate, setConversionRate] = useState(0); // Ajouter l'état pour stocker le taux de conversion

  useEffect(() => {
    // Récupérer le taux de conversion EGLD/$ lors du chargement de l'application
    fetchConversionRate();
  }, []);

  const fetchConversionRate = async () => {
    try {
      const response = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=elrond-erd-2&vs_currencies=usd');
      const data = await response.json();
      const rate = data['elrond-erd-2'].usd; // Récupérer le taux de conversion EGLD/$ depuis la réponse

      setConversionRate(rate);
    } catch (error) {
      console.error('Une erreur s\'est produite lors de la récupération du taux de conversion :', error);
    }
  };

  const handleQuantityChange = (name, value) => {
    if (name === 'follow' || name === 'discord') {
      value = value ? 1 : 0;
    }
    setQuantities((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleNumberOfYetisChange = (value) => {
    const newValue = value > 500 ? 500 : value;
    setNumberOfYetis(newValue);
  };

  const handleBudgetPrevisionnelChange = (value) => {
    setBudgetPrevisionnel(value);
  };

  const handleDoxxedChange = (value) => {
    setDoxxed(value);
  };

  const handlePaiementHorsMintChange = (value) => {
    setPaiementHorsMint(value);
  };

  const calculateTotalPrice = () => {
    const threadPrice = quantities.thread * pricesData.threadPrice;
    const tweetPrice = quantities.tweet * pricesData.tweetPrice;
    const followPrice = quantities.follow * pricesData.followPrice;
    const discordPrice = quantities.discord * pricesData.discordPrice;
    const likeRtPrice = quantities.likeRT * pricesData.likeRtPrice;
    const raidPrice = quantities.raid * pricesData.raidPrice;

    const participationRate = numberOfYetis / 500;
    let totalPrice = (threadPrice + tweetPrice + followPrice + discordPrice + likeRtPrice + raidPrice) * participationRate;

    if (budgetPrevisionnel) {
      totalPrice -= totalPrice * 0.05;
    }

    if (doxxed) {
      totalPrice -= totalPrice * 0.05;
    }

    if (paiementHorsMint) {
      totalPrice -= totalPrice * 0.05;
    }

    const totalQuests = calculateTotalQuests();

    if (totalQuests > 5) {
      totalPrice -= totalPrice * 0.025;
    }

    if (totalQuests > 10) {
      totalPrice -= totalPrice * 0.05;
    }

    return totalPrice;
  };

  const calculateDaoShare = () => {
    const totalPrice = calculateTotalPrice();
    return totalPrice * 0.1;
  };

  const calculateRewardsPerYeti = () => {
    const totalPrice = calculateTotalPrice();
    const numberOfParticipants = numberOfYetis > 0 ? numberOfYetis : 1;
    return totalPrice * 0.9 / numberOfParticipants;
  };

  const calculateHourlyRate = () => {
    const rewardsPerYeti = calculateRewardsPerYeti();
    const timePerQuest = 60;
    const totalQuests = calculateTotalQuests();
    const hourlyRate = 3600 / (timePerQuest * totalQuests) * rewardsPerYeti;

    return hourlyRate;
  };

  const calculateTotalQuests = () => {
    const totalQuests = Object.values(quantities).reduce((total, quantity) => total + quantity, 0);
    return totalQuests;
  };

  const calculateTotalReductions = () => {
    let totalReductions = 0;

    if (budgetPrevisionnel) {
      totalReductions += calculateTotalPrice() * 0.05;
    }

    if (doxxed) {
      totalReductions += calculateTotalPrice() * 0.05;
    }

    if (paiementHorsMint) {
      totalReductions += calculateTotalPrice() * 0.05;
    }

    const totalQuests = calculateTotalQuests();

    if (totalQuests > 5) {
      totalReductions += calculateTotalPrice() * 0.025;
    }

    if (totalQuests > 10) {
      totalReductions += calculateTotalPrice() * 0.05;
    }

    return totalReductions;
  };

  const calculateRewardsPerYetiInEGLD = () => {
    const rewardsPerYetiUSD = calculateRewardsPerYeti();
    const rewardsPerYetiEGLD = rewardsPerYetiUSD / conversionRate;

    return rewardsPerYetiEGLD;
  };

  return (
    <div>
      <h1>Service simulator</h1>
      <div>
      <QuantitiesInput
        quantities={quantities}
        onQuantityChange={handleQuantityChange}
        numberOfYetis={numberOfYetis}
        onNumberOfYetisChange={handleNumberOfYetisChange}
      />
      <Reduction
        budgetPrevisionnel={budgetPrevisionnel}
        onBudgetPrevisionnelChange={handleBudgetPrevisionnelChange}
        doxxed={doxxed}
        onDoxxedChange={handleDoxxedChange}
        paiementHorsMint={paiementHorsMint}
        onPaiementHorsMintChange={handlePaiementHorsMintChange}
      />
      </div>
      <ResultsDisplay
        quantities={quantities}
        totalPrice={calculateTotalPrice()}
        daoShare={calculateDaoShare()}
        rewardsPerYeti={calculateRewardsPerYeti()}
        rewardsPerYetiInEGLD={calculateRewardsPerYetiInEGLD()}
        hourlyRate={calculateHourlyRate()}
        totalQuests={calculateTotalQuests()}
        totalReductions={calculateTotalReductions()}
      />
    </div>
  );
  
};

export default App;
