import React from 'react';

const QuantitiesInput = ({ quantities, onQuantityChange, numberOfYetis, onNumberOfYetisChange }) => {
  const handleQuantityChange = (e) => {
    const { name, value, type, checked } = e.target;
    let newValue;

    if (type === 'checkbox') {
      newValue = checked ? 1 : 0;
    } else {
      newValue = parseInt(value, 10);
      newValue = isNaN(newValue) ? 0 : Math.max(newValue, 0);
    }

    onQuantityChange(name, newValue);
  };

  const handleNumberOfYetisChange = (e) => {
    let value = parseInt(e.target.value, 10);
    if (value > 500) {
      value = 500;
      alert("No more than 500 yetis!");
    }
    onNumberOfYetisChange(value);
  };

  const participationRate = (numberOfYetis / 500) * 100; // Calcul du taux de participation en pourcentage

  return (
    <div id='choices'>
      <div className='choicesInput'>
        <h2>Pick your bundle</h2>
      <label>
        Thread : 
        <input
          type="checkbox"
          name="thread"
          value={quantities.thread}
          onChange={handleQuantityChange}
        />
      </label>

      <label>
        Follow account : 
        <input
          type="checkbox"
          name="follow"
          checked={quantities.follow > 0}
          onChange={handleQuantityChange}
        />
      </label>
      <label>
        Join Discord : 
        <input
          type="checkbox"
          name="discord"
          checked={quantities.discord > 0}
          onChange={handleQuantityChange}
        />
      </label>
      <label>
        Tweet about : 
        <input
          type="range"
          min="0"
          max="20"
          step="1"
          name="tweet"
          value={quantities.tweet}
          onChange={handleQuantityChange}
        />
        {quantities.tweet}
      </label>
      <label>
        Like & RT : 
        <input
          type="range"
          min="0"
          max="20"
          step="1"
          name="likeRT"
          value={quantities.likeRT}
          onChange={handleQuantityChange}
        />
         {quantities.likeRT}
      </label>
      <label>
        Raid : 
        <input
          type="range"
          min="0"
          max="20"
          step="1"
          name="raid"
          value={quantities.raid}
          onChange={handleQuantityChange}
        />
         {quantities.raid}
      </label>
      </div>
      <div className='choicesInput'>
      <h2>Participation Rate</h2>
      <label>
        Number of yetis :
        <input
          type="number"
          value={numberOfYetis}
          onChange={handleNumberOfYetisChange}
          max={500} // Limite le nombre maximum à 500
        />
      </label>
      {participationRate.toFixed(2)}%
      </div>
    </div>
  );
};

export default QuantitiesInput;
